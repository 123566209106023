export default {
	apiGateway: {
		REGION: 'us-east-1',
		URL: ' https://wgc92toh40.execute-api.us-east-1.amazonaws.com/prod'
		//URL: ' https://qjpyai8mli.execute-api.us-east-1.amazonaws.com/dev'
	},
	cognito: {
		REGION: 'us-east-1',
		USER_POOL_ID: 'us-east-1_o39QRIXoz',
		APP_CLIENT_ID: '980e6icg2mre6ks8qmlmgvt4k',
		IDENTITY_POOL_ID: 'us-east-1:5b4492a8-d387-4489-bf37-2b845db8ca00'
	}
};
