import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLogin } from '../utils/utils';
import { Auth } from 'aws-amplify';
import CONST from '../utils/constants'


const PrivateRoute = ({component: Component, ...rest}) => {
    // console.log(" >>>>>> PrivateRoute: ");

	const [ login, setLogin ] = useState(null);

    useEffect(() => {
        isLogin()
    }, []);

	async function isLogin() {
		try {
            //let currentSession = await Auth.currentSession();
            //let jwtToken = currentSession.getAccessToken().getJwtToken();
            //console.log(" >>>>>> JwtToken: " +jwtToken);
            // const _token = user.signInUserSession.idToken.jwtToken;

            let jwtToken = localStorage.getItem(CONST.KEY_TOKEN_JWT);
            if(jwtToken){
                //console.log(" ******* Token: " + jwtToken);
                //const cognitoUser = await Auth.currentAuthenticatedUser();
                //const currentSession = await Auth.currentSession();

                const cognitoUser = await Auth.currentAuthenticatedUser({bypassCache: true});
                // console.log(" >>>>> Token: " +JSON.stringify(cognitoUser));

                /*if(cognitoUser){
                    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
                        console.log('session', err, session);
                        const { idToken, refreshToken, accessToken } = session;
                        console.log(" >>>>> Token: " +idToken.jwtToken);
                      });
                }*/

            } else {
                console.log(" ******* No Token!!! ");
            }

            /*
            if(Auth){
                const cognitoUser = await Auth.currentAuthenticatedUser();
                const currentSession = await Auth.currentSession();
                let jwtToken = currentSession.getAccessToken().getJwtToken();
                console.log(" >>>>>> JwtToken: " +jwtToken);
    
                if(cognitoUser){
                    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
                        console.log('session', err, session);
                        const { idToken, refreshToken, accessToken } = session;
                        console.log(" >>>>> Token: " +idToken.jwtToken);
                      });
                }
                if(currentSession){
                    setLogin(true);
                } else {
                    setLogin(null);
                }
            }
            */


            
		} catch (e) {
			alert("***** Error PrivateRoute: " + e.message);
		}
	};

    return (
        <Route {...rest} render={props => ( isLogin() ? <Component {...props} /> : <Redirect to="/assets" /> )} />
    );
};

export default PrivateRoute;