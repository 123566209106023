export default {
    // private
    assets: '/assets',
    fund: '/fund',
    trading: '/trading',
    orders: '/trading/orders',
    journals: '/trading/journals',
    positions: '/trading/positions',
    streams: '/streams',
    myTSalary: '/myTSalary',
    goals: '/goals',
    settings: '/settings',
    helpCenter: '/helpCenter',
    
    // auth
    home: '/',
    login: '/login',
    signup: '/signup',
    forgotPassword: '/forgot_password'
};
