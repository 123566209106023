import React, { Suspense, lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import LoadingComponent from 'components/loading';

import PrivateRoute from '../components/PrivateRoute';
import PublicRoute from '../components/PublicRoute';


const HomeComponent = lazy(() => import('./home/HomeComponent'));
const LoginComponent = lazy(() => import('./login/LoginComponent'));
const SignupComponent = lazy(() => import('./signup/SignupComponent'));
const DashboardComponent = lazy(() => import('./dashboard/DashboardComponent'));
const TransferComponent = lazy(() => import('../routes/fund/TransferComponent'));
const JournalsComponent = lazy(() => import('../routes/transactions/journals/JournalsComponent'));
const OrdersComponent = lazy(() => import('../routes/transactions/orders/OrdersComponent'));
const PositionsComponent = lazy(() => import('../routes/transactions/positions/PositionsComponent'));

function GeneralRoutes() {

    console.log(" >>>> Routers: ");
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Switch>
                <PrivateRoute exact path={SLUGS.assets}        component={DashboardComponent} />
                <PrivateRoute exact path={SLUGS.fund}          component={TransferComponent} />
                <PrivateRoute exact path={SLUGS.trading}       render={() => <div>Trading</div>} />
                <PrivateRoute exact path={SLUGS.orders}        component={OrdersComponent} />
                <PrivateRoute exact path={SLUGS.journals}      component={JournalsComponent} />
                <PrivateRoute exact path={SLUGS.positions}      component={PositionsComponent} />
                
                <PublicRoute exact path={SLUGS.home}           component={HomeComponent} restricted={true}/>
                <PublicRoute exact path={SLUGS.login}          component={LoginComponent} restricted={true} />
                <PublicRoute exact path={SLUGS.signup}         component={SignupComponent} restricted={true}/>
                <PublicRoute exact path={SLUGS.forgotPassword} render={() => <div>forgotPassword</div>} />

                <Redirect to={SLUGS.home} />

            </Switch>
        </Suspense>
    );
}

export default GeneralRoutes;
