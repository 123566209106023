import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { isLogin } from '../utils/utils';
import { Auth } from 'aws-amplify';
import CONST from '../utils/constants'

const PublicRoute = ({component: Component, restricted, ...rest}) => {
    //console.log(" >>>>>> PublicRoute: ");

	const [ login, setLogin ] = useState(null);

    useEffect(() => {
        isLogin()
    }, []);

    async function isLogin() {
        // const jwtToken = localStorage.getItem(CONST.KEY_TOKEN_JWT);

        /*
        const jwtToken = Auth.currentSession();
        console.log(" **** jwtToken: " + JSON.stringify(jwtToken));
        
        //console.log(" >>>>> JwtToken: " + jwtToken);
        if(jwtToken.token){

            const user = await Auth.currentAuthenticatedUser();
            const _token = user.signInUserSession.idToken.jwtToken;
            console.log(" >>>>> _token: " + _token);

            //localStorage.setItem(CONST.KEY_TOKEN_JWT, _token);
            setLogin(true);
        } else {
        }*/
        
        setLogin(false);
    }

    return (
        <Route {...rest} render={props => (login && restricted ? <Redirect to="/" /> : <Component {...props} /> )} />
    );
};

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export default PublicRoute;