import { Auth } from 'aws-amplify';
import CONST from './constants'

export const login = async data => {
    const user = await Auth.currentAuthenticatedUser();
    const _token = user.signInUserSession.idToken.jwtToken;

    localStorage.setItem(CONST.KEY_TOKEN_JWT, _token);
    return true;
}

export const logout = () => {
    localStorage.removeItem(CONST.KEY_TOKEN_JWT);
    localStorage.removeItem(CONST.KEY_RELATIONSHIP_ID);
}

export const isLogin = () => {
    console.log(" >>> IsLogin: login ...");
    if (localStorage.getItem(CONST.KEY_TOKEN_JWT)) {
        return true;
    }

    return false;
}